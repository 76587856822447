.like-rate-high {
  color: #00af9b;
}

.like-rate-mid {
  color: #ffb800;
}

.like-rate-low {
  color: #ff2d55;
}

.ac-rate-high {
  color: #00af9b;
}

.ac-rate-mid {
  color: #ffb800;
}

.ac-rate-low {
  color: #ff2d55;
}

.difficulty-easy {
  color: #00af9b;
}

.difficulty-medium {
  color: #ffb800;
}

.difficulty-hard {
  color: #ff2d55;
}

.all-problems-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.search-id-dropdown {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}

.search-title-dropdown {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}

.topics-container {
  margin-bottom: -4px;
}

.topic-tag {
  margin-bottom: 4px;
  cursor: pointer;
}

.popularity-tooltip {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4px;
}

.ac-rate-tooltip {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4px;
}

.tooltip-value {
  text-align: right;
}
